import { Injectable } from '@angular/core';
import { SharedService } from '../AngularSpecific/shared.service';
import { MainHubService } from './mainHub.service';
import { SharedEventNameEnum } from '@smarttask-common/src/lib/models/AngularSpecific/SharedEventNameEnum';
import { SharedEventModel } from '@smarttask-common/src/lib/models/AngularSpecific/SharedEventModel';
import { OrganizationlistStoreService } from '../Organization/store/organizationlistStore.service';
import * as signalr from '@microsoft/signalr';
import { MembershipBaseModel } from '@smarttask-common/src/lib/models/Membership/Core/MembershipBaseModel';

@Injectable({
    providedIn: 'root',
})
export class MembershipHubService {
    private previous_organization_id: number;

    constructor(
        private sharedService: SharedService,
        private mainHubService: MainHubService,
        private organizationlistStoreService: OrganizationlistStoreService,
    ) {
        this.organizationlistStoreService.watchSelectedOrganizationId().subscribe((organization_id) => {
            if (this.previous_organization_id != undefined && organization_id == undefined) this.unsubscribe(this.previous_organization_id);
            if (organization_id != undefined) this.subscribeToNewOrganization(organization_id);
        });

        this.listenForReconnection(); //On SignalR reconnection after getting disconnected
    }

    listenForReconnection = () => {
        this.sharedService.on(SharedEventNameEnum.signalr_reconnected, () => {
            let selected_organization_id = this.organizationlistStoreService.getSelectedOrganizationId();
            if (selected_organization_id) this.subscribeToNewOrganization(selected_organization_id);
            this.trackEvents();
        });
    };

    subscribeToNewOrganization = (organization_id: number) => {
        //organization's event and subscribe to new organization's events
        if (this.mainHubService.connection.state == signalr.HubConnectionState.Connected) {
            if (this.previous_organization_id != undefined) {
                //Unsubscribe from last Organization's SignalR events
                this.unsubscribe(this.previous_organization_id).then(() => {
                    this.subscribe(organization_id);
                });
            } else {
                this.subscribe(organization_id);
            }
            this.previous_organization_id = organization_id;
        }
    };

    trackEvents = () => {
        //Triggered Events

        this.mainHubService.connection.on(SharedEventNameEnum.membership_add, (members: Array<MembershipBaseModel>) => {
            var event: SharedEventModel = {
                name: SharedEventNameEnum.membership_add,
                obj: {
                    members: members,
                },
            };
            this.sharedService.broadcast(event);
        });

        this.mainHubService.connection.on(SharedEventNameEnum.membership_update_role, (members: Array<MembershipBaseModel>) => {
            var event: SharedEventModel = {
                name: SharedEventNameEnum.membership_update_role,
                obj: {
                    members: members,
                },
            };
            this.sharedService.broadcast(event);
        });

        this.mainHubService.connection.on(SharedEventNameEnum.membership_remove, (members: Array<MembershipBaseModel>) => {
            var event: SharedEventModel = {
                name: SharedEventNameEnum.membership_remove,
                obj: {
                    members: members,
                },
            };
            this.sharedService.broadcast(event);
        });
    };

    subscribe = (organization_id: number) => {
        try {
            if (this.mainHubService.connection == undefined) {
                console.log('SignalR Proxy unavailable');
                return undefined;
            }
            return this.mainHubService.connection.invoke(SharedEventNameEnum.membership_subscribe, organization_id);
        } catch (e) {
            console.error(e);
        }
        return undefined;
    };

    unsubscribe = (organization_id: number) => {
        try {
            if (this.mainHubService.connection == undefined) {
                console.log('SignalR Proxy unavailable');
                return undefined;
            }
            return this.mainHubService.connection.invoke(SharedEventNameEnum.membership_unsubscribe, organization_id);
        } catch (e) {
            console.error(e);
        }
        return undefined;
    };
}
